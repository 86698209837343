<template>
  <div class="bulletin-board-container">
    <header class="bulletin-board-header">
      <div class="header-actions" @click="doNewItem">
        <div class="action-item">
          <svg width="20" height="20"><use xlink:href="#iconshoujiApp" /></svg>
          <span>新增</span>
        </div>
        <!-- 下拉选择暂时取消 -->
        <!-- <el-dropdown @command="doDropdownClick" placement="bottom-start">
          <div class="action-item">
            <svg width="20" height="20"><use xlink:href="#iconshoujiApp" /></svg>
            <span>新增</span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="statistics">
              <div class="action-item">
                <svg width="20" height="20"><use xlink:href="#iconjiangong" /></svg>
                <span style="width: 60px">统计</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item command="chart">
              <div class="action-item">
                <svg width="20" height="20"><use xlink:href="#icona-tongjibaobiaohover" /></svg>
                <span>图表</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
      <div class="header-actions">
        <div class="action-item" @click.stop="doPreview">
          <svg width="20" height="20"><use xlink:href="#iconzhuomianyingyong" /></svg>
          <span>预览</span>
        </div>
        <div class="action-item" @click.stop="doSave">
          <svg width="20" height="20"><use xlink:href="#iconhetongwenjian" /></svg>
          <span> {{ btnLoading ? '保存中...' : '保存'}}</span>
        </div>
      </div>
    </header>
    <section v-loading="loading" class="bulletin-board-warp" ref="warpRef">
      <GridLayout
        v-if="layout.length"
        :layout.sync="layout"
        :col-num="layoutConfig.colNum"
        :row-height="layoutConfig.rowHeight"
        :is-draggable="layoutConfig.isDraggable"
        :is-resizable="layoutConfig.isResizable"
        :is-mirrored="layoutConfig.isMirrored"
        :vertical-compact="layoutConfig.verticalCompact"
        :margin="layoutConfig.margin"
        :use-css-transforms="layoutConfig.useCssTransforms"
      >
        <GridItem 
          v-for="item in layout"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :key="item.i"
          :minW="3"
          :minH="3"
          drag-allow-from=".draggable-handle"
          drag-ignore-from=".item-warp"
        >
          <div class="gird-item-warp">
            <div class="item-warp">
              <header class="item-header">
                <input v-model="item.data.title" class="item-title">
                <div class="header-action">
                  <div class="action-item" @click.stop="doFullScreen(item)">
                    <svg width="16" height="16"><use xlink:href="#icondaping-quanping"></use></svg>
                  </div>
                  <el-dropdown @command="(cmd) => { doInnerDropdownClick(cmd, item) }">
                    <div class="action-item">
                      <svg width="16" height="16"><use xlink:href="#iconbimgis_gengduo"></use></svg>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="edit">
                        <div class="action-item">
                          <svg width="20" height="20"><use xlink:href="#iconbianji2" /></svg>
                          <span style="width: 60px">编辑</span>
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item command="delete">
                        <div class="action-item" type="delete">
                          <svg width="20" height="20"><use xlink:href="#iconwangpan-shanchu1x" /></svg>
                          <span>删除</span>
                        </div>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </header>
              <main class="item-main">
                <Chart :data="item.data"></Chart>
              </main>
            </div>
            <div class="draggable-handle">
              <svg width="20" height="20"><use xlink:href="#iconjijia_tuozhuai"></use></svg>
            </div>
          </div>
        </GridItem>
      </GridLayout>
      <el-empty v-else description="暂无内容"></el-empty>
    </section>
    <el-dialog
      :visible.sync="dialogConfig.dialogVisible"
      :title="dialogConfig.data.title"
      :width="dialogConfig.width"
      :close-on-click-modal="dialogConfig.closeOnClickModal"
      custom-class="bulletin-board-dialog"
    >
      <header class="dialog-header" slot="title">
        <input v-if="!dialogConfig.preview" class="title-input" v-model="dialogConfig.data.title" />
        <span v-else class="title-input">{{ dialogConfig.data.data.title }}</span>
      </header>
      <NewItem v-if="dialogConfig.dialogVisible && !dialogConfig.preview" :data.sync="dialogConfig.data" @submit="handleSubmit" />
      <div v-if="dialogConfig.dialogVisible && dialogConfig.preview" class="preview">
        <Chart :data="dialogConfig.data.data" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import VueGridLayout from 'vue-grid-layout'
import { Dropdown, DropdownMenu, DropdownItem, MessageBox, Empty } from 'element-ui'
import NewItem from './components/NewItem.vue'
import Chart from './components/Chart.vue'
import ETLSelect from './components/ETLSelect'
import { chart } from '@/saas-apis/bulletinBoard.js'
export default{
  name: 'BulletinBoard',
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    'el-dropdown': Dropdown,
    'el-dropdown-menu': DropdownMenu,
    'el-dropdown-item': DropdownItem,
    NewItem,
    Chart,
    'el-empty': Empty
  },
  data() {
    return {
      sourceData: {},
      loading: false,
      btnLoading: false,
      layout: [],
      layoutConfig: {
        colNum: 12, // 行数
        rowHeight: 30, // 小项高度
        isDraggable: true, // 可拖拽
        isResizable: true, // 可改变大小
        isMirrored: false,
        margin: [10, 10], // 间隔
        verticalCompact: true,
        useCssTransforms: true // css转变
      },
      dialogConfig: { // 弹窗配置
        dialogVisible: false,
        data: {},
        title: '',
        width: '50%',
        currentId: null,
        preview: false,
        closeOnClickModal: false
      },
      etlChosedData: null
    }
  },
  methods: {
    // 初始化容器的高度 => 主要是为了让区域内小项12的高度刚好占满区域
    initWarpEve() {
      const warp = this.$refs.warpRef
      const height = warp.getBoundingClientRect().height
      const cols = this.layoutConfig.colNum
      this.layoutConfig.rowHeight = ((height - 10) / cols) - 10
    },
    doNewItem() {
      const top = this
      const h = this.$createElement
      MessageBox({
        title: '选择数据源',
        message: h(ETLSelect, { on: { change: ( res ) => { top.etlChosedData = res } } }, []),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'class-tree-message-box',
        beforeClose: (action, instance, done) => {
          if(action === 'confirm') {
            if(top.etlChosedData) {
              done()
            } else {
              this.$message.error('请选择数据源')
            }
          } else {
            done()
          }
        }
      }).then(() => {
        this.dialogConfig.preview = false
        this.dialogConfig.closeOnClickModal = false
        this.dialogConfig.data = {
          title: '图表',
          etlConfig: top.etlChosedData,
          chartData: {},
          key: 0
        }
        this.dialogConfig.currentId = null
        this.dialogConfig.width = '70%'
        this.dialogConfig.dialogVisible = true
      }).catch(() => {
        // 无事可做
      })
    },
    // 处理小项下拉
    doInnerDropdownClick(cmd, data) {
      switch(cmd) {
        case 'delete': {
          this.doDeleteItem(data)
          break
        }
        case 'edit': {
          this.doItemEditor(data)
          break
        }
      }
    },
    // 删除
    doDeleteItem(data) {
      this.$confirm('删除后不可恢复, 是否继续?', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'liner-message-box'
      }).then(() => {
        const index = this.layout.findIndex(el => el.i === data.i)
        if(index > -1) {
          this.layout.splice(index, 1)
        }
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        // 暂时没有事情可做
      });
    },
    // 小项编辑
    doItemEditor(data) {
      this.dialogConfig.preview = false
      this.dialogConfig.closeOnClickModal = false
      this.dialogConfig.data = data.data
      this.dialogConfig.width = '70%'
      this.dialogConfig.currentId = data.i
      this.dialogConfig.dialogVisible = true
    },
    // 全屏
    doFullScreen(data) {
      this.dialogConfig.preview = true
      this.dialogConfig.closeOnClickModal = true
      this.dialogConfig.data = data
      this.dialogConfig.width = '70%'
      this.dialogConfig.currentId = data.data.i
      this.dialogConfig.dialogVisible = true
    },
    // 提交
    handleSubmit() {
      if(this.dialogConfig.currentId) {
        this.dialogConfig.data.key += 1
      } else {
        const i = this.getKey()
        this.layout.push({
          x: (this.layout.length * 4) % (this.layoutConfig.colNum || 12),
          y: this.layout.length + (this.layoutConfig.colNum || 12), // puts it at the bottom
          w: 4,
          h: 4,
          data: this.dialogConfig.data,
          i
        })
      }
      this.dialogConfig.dialogVisible = false
    },
    getKey() {
      const num = new Date().getTime()
      const num2 = Math.floor(Math.random() * 10000000)
      const key = Number(num + '' + num2).toString(16)
      return key + ''
    },
    // 预览
    doPreview() {
      this.$router.push({
        name: 'BulletinBoardShower',
        query: {
          chart_uuid: this.sourceData.uuid, 
          id: this.sourceData.id,
          name: this.$route?.query?.name || '-'
        }
      })
    },
    // 保存
    doSave() {
      if(this.btnLoading) return
      this.btnLoading = true
      chart.update(this.sourceData.uuid, { content: this.layout }).then(res => {
        if(res.data.code === 200) {
          this.$message.success(res.data.msg)
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        this.btnLoading = false
      })
    },
    getData() {
      const { chart_uuid = 0 } = this.$route.query
      if(chart_uuid) {
        this.loading = true
        chart.details(chart_uuid).then(res => {
          if(res.data.code === 200) {
            this.sourceData = res.data.data
            if(Array.isArray(res.data.data.content)) {
              this.layout = res.data.data.content
            } else {
              this.sourceData.content = []
            }
          }
        }).catch(err => {
          console.error(err)
        }).finally(() => {
          this.loading = false
        })
      }
    }
  },
  created() {
    this.getData()
  },
  mounted() {
    this.$nextTick().then(() => {
      if(this.$refs.warpRef) {
        // 初始化容器事件
        this.initWarpEve()
      }
    })
  }
}
</script>
<style lang="less" scoped>
  @import "../../css/manageAdd.less";
  .bulletin-board-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    box-sizing: border-box;
    background: #fff;
    .bulletin-board-header{
      flex-shrink: 0;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      box-sizing: border-box;
      border-bottom: 1px solid #f5f5f5;
      .header-actions{
        display: flex;
        align-items: center;
        .action-item{
          display: flex;
          align-items: center;
          padding: 6px 10px;
          border-radius: 4px;
          &:hover{
            cursor: pointer;
            background: #409eff33;
            color: #409eff;
          }
          svg + span{
            margin-left: 8px;
          }
        }
      }
    }
    .bulletin-board-warp{
      flex-grow: 1;
      height: 10px;
      overflow: auto;
      :deep(.vue-grid-item){
        box-sizing: border-box;
        touch-action: none;
        .vue-resizable-handle{
          visibility: hidden;
          bottom: 5px;
          right: 5px;
          transform: scale(1.5);
        }
        &:hover{
          .vue-resizable-handle{
            visibility: visible;
          }
        }
      }
      .gird-item-warp{
        position: relative;
        width: 100%;
        height: 100%;
        padding: 5px;
        background: #fff;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px solid #f1f1f1;
        &:hover{
          border: 1px solid #409eff;
          .draggable-handle{
            visibility: visible;
          }
        }
        .draggable-handle{
          visibility: hidden;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        .item-warp{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          &:hover{
            .item-header{
              .header-action{
                visibility: visible;
              }
            }
          }
          .item-header{
            flex-shrink: 0;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .item-title{
              width: 10px;
              flex-grow: 1;
              flex-shrink: 1;
              display: inline-block;
              padding: 4px;
              border-radius: 4px;
              border: none;
              box-sizing: border-box;
              font-size: 16px;
              font-weight: 600;
              &:focus{
                border: 1px solid #409eff;
              }
            }
            .header-action{
              visibility: hidden;
              display: flex;
              align-items: center;
              .action-item{
                padding: 6px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                 &:hover{
                  cursor: pointer;
                  background: #409eff33;
                  color: #409eff;
                }
                svg + span{
                  margin-left: 8px;
                }
                & + .action-item{
                  margin-left: 10px;
                }
              }
            }
          }
          .item-main{
            flex-grow: 1;
            overflow: hidden;
          }
        }
      }
    }
  }
</style>
<style lang="less">
.action-item{
  display: flex;
  align-items: center;
  svg + span{
    margin-left: 8px;
  }
}
.action-item[type="delete"] {
  color: #ff0000;
  svg{
    path{
      fill: red !important;
    }
  }
}
.bulletin-board-container .vue-grid-item.vue-grid-placeholder {
  background: #409effaa;
}
.bulletin-board-dialog{
  .dialog-header{
    .title-input{
      font-size: 18px;
      border: none;
      padding: 2px 6px;
      border-radius: 2px;
      background: transparent;
      &:focus{
        border: 1px solid #409eff;
      }
    }
  }
  .el-dialog__body{
    padding: 0 20px 30px 20px;
    .preview{
      height: 60vh;
    }
  }
}
.class-tree-message-box {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: none;
  .el-message-box__header{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 48px;
    background: linear-gradient(90deg, #6292FF 0%, #DE93FF 84%, #CE92FF 91%, #C568FF 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 68px 0 24px;
    color: #fff;
    .el-message-box__title{
      color: #fff;
      font-size: 14px;
    }
    .el-message-box__headerbtn{
      top: 14px;
      right: 24px;
      .el-message-box__close{
        color: #fff;
          font-size: 20px;
      }
    }
  }
}
.liner-message-box {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: none;
  .el-message-box__header{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 48px;
    background: linear-gradient(90deg, #6292FF 0%, #DE93FF 84%, #CE92FF 91%, #C568FF 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 68px 0 24px;
    color: #fff;
    .el-message-box__title{
      color: #fff;
      font-size: 14px;
    }
    .el-message-box__headerbtn{
      top: 14px;
      right: 24px;
      .el-message-box__close{
        color: #fff;
          font-size: 20px;
      }
    }
  }
}
</style>
