<template>
  <div class="chart-options-container">
    <template v-if="data.type === 'bar'">
      <div class="select-btn" :class="{ active: !data.attributes.distinguishColor  && !data.attributes.barChart }" @click="setBarOptions()">
        <svg width="20" height="20">
          <use xlink:href="iconshuzhu" />
        </svg>
        <span>普通柱状图</span>
      </div>
      <div class="select-btn" :class="{ active: data.attributes.distinguishColor && !data.attributes.barChart }" @click="setBarOptions('distinguishColor')">
        <svg width="20" height="20">
          <use xlink:href="iconshuzhu" />
        </svg>
        <span>区分主轴柱状图</span>
      </div>
      <div class="select-btn" :class="{ active: !data.attributes.distinguishColor && data.attributes.barChart }" @click="setBarOptions('barChart')">
        <svg width="20" height="20">
          <use xlink:href="iconhengzhu" />
        </svg>
        <span>普通条状图</span>
      </div>
      <div class="select-btn" :class="{ active: data.attributes.distinguishColor && data.attributes.barChart }" @click="setBarOptions('distinguishColor', 'barChart')">
        <svg width="20" height="20">
          <use xlink:href="iconhengzhu" />
        </svg>
        <span>区分主轴条状图</span>
      </div>
    </template>
    <template v-if="data.type === 'line'">
      <div class="select-btn" :class="{ active: !data.attributes.smooth }" @click.stop="setLineOptions()">
        <svg width="20" height="20">
          <use xlink:href="iconzhe" />
        </svg>
        <span>普通折线图</span>
      </div>
      <div class="select-btn" :class="{ active: data.attributes.smooth }" @click.stop="setLineOptions('smooth')">
        <svg width="20" height="20">
          <use xlink:href="iconzhe" />
        </svg>
        <span>平滑折线图</span>
      </div>
    </template>
    <template v-if="data.type === 'pie'">
      <div class="select-btn" :class="{ active: !data.attributes.changeToRing && !data.attributes.isRose }" @click.stop="setPieOptions()">
        <svg width="20" height="20">
          <use xlink:href="iconbing" />
        </svg>
        <span>普通饼图</span>
      </div>
      <div class="select-btn" :class="{ active: !data.attributes.isRose && data.attributes.changeToRing }" @click.stop="setPieOptions('changeToRing')">
        <svg width="20" height="20">
          <use xlink:href="iconhuan" />
        </svg>
        <span>普通环图</span>
      </div>
      <div class="select-btn" :class="{ active: data.attributes.isRose && !data.attributes.changeToRing }" @click.stop="setPieOptions('isRose')">
        <svg width="20" height="20">
          <use xlink:href="iconhuan" />
        </svg>
        <span>南丁格尔图</span>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
      require: true
    }
  },
  watch: {
    'data.type': {
      handler(n, o) {
        if(n !== o) {
          this.setDefaultOptions(n)
        }
      },
      immediate: true
    }
  },
  methods: {
    setDefaultOptions(n) {
      switch(n) {
        case 'bar': {
          const obj = {
            useArea: false,
            useGradient: false,
            legendRight: 'auto',
            legendTop: 'auto',
            legendOrient: 'horizontal',
            legendIcon: 'roundRect'
          }
          this.data.attributes = Object.assign(this.data.attributes, obj)
          break
        }
        case 'line': {
          const obj = {
            useArea: true,
            useGradient: true,
            legendRight: 'auto',
            legendTop: 'auto',
            legendOrient: 'horizontal',
            legendIcon: 'roundRect'
          }
          this.data.attributes = Object.assign(this.data.attributes, obj)
          break
        }
        case 'pie': {
          const obj = {
            useArea: false,
            useGradient: false,
            legendRight: '10%',
            legendTop: 'center',
            legendOrient: 'vertical',
            legendIcon: 'diamond',
            pieTop: '50%',
            pieLeft: '35%',
            borderWidth:0,
            borderRadius:0, // 间距圆角
            borderColor:'#FFF' // 间距颜色
          }
          this.data.attributes = Object.assign(this.data.attributes, obj)
          break
        }
        case 'countup': {
          this.data.attributes = {}
        }
      }
    },
    setBarOptions() {
      const keys = ['distinguishColor', 'barChart']
      const arr = Array.from(arguments)
      keys.map(el => {
        if(arr.includes(el)) {
          this.data.attributes[el] = true
        } else {
          this.data.attributes[el] = false
        }
      })
      this.$emit('sure')
    },
    setLineOptions() {
      const keys = ['smooth']
      const arr = Array.from(arguments)
      keys.map(el => {
        if(arr.includes(el)) {
          this.data.attributes[el] = true
        } else {
          this.data.attributes[el] = false
        }
      })
      this.$emit('sure')
    },
    setPieOptions() {
      const keys = ['isRose', 'changeToRing']
      const arr = Array.from(arguments)
      keys.map(el => {
        if(arr.includes(el)) {
          this.data.attributes[el] = true
        } else {
          this.data.attributes[el] = false
        }
      })
      this.$emit('sure')
    }
  }
}
</script>
<style lang="less" scoped>
  .chart-options-container{
    padding: 5px;
    box-sizing: border-box;
    width: 100%;
    .select-btn {
      padding: 10px 5px;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      align-items: center;
      & span{
        margin-left: 10px;
      }
      &:hover{
        background: #409eff33;
        color: #409eff;
      }
    }
    .active{
      background: #409eff33;
      color: #409eff;
    }
  }
</style>
