export default {
  showTitle: false, // 不再显示标题
  top: 30, // 网格
  left: 20,
  right: 20,
  bottom: 20,
  colorRange: ['#F76D71', '#FA9A5A', '#59CC7F', '#5CA3FF', '#9585FF'],
  lineColorRange: ['#F76D71', '#FA9A5A', '#59CC7F', '#5CA3FF', '#9585FF'],
  XColorRange: ['#F76D71', '#FA9A5A', '#59CC7F', '#5CA3FF', '#9585FF'],
  gradientRange: [
    ['rgba(255,255,255,0)', '#F76D71'],
    ['rgba(255,255,255,0)', '#FA9A5A'],
    ['rgba(255,255,255,0)', '#59CC7F'],
    ['rgba(255,255,255,0)', '#5CA3FF'],
    ['rgba(255,255,255,0)', '#9585FF']
  ], // 渐变颜色组
}