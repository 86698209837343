/*
 * @Description: 
 * @Author: luocheng
 * @Date: 2022-01-10 16:24:02
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-12 10:45:28
 */

export const defaultConfig = {
  name: '', // 名称(去除)
  type: '', // 图表类型
  component: '', // 组件
  id: '',
  configs: [ // 数据仓库配置
    {
      view_uuid: '',
      chartType: '',
      x: {
        // 维度
        field_uuid: '',
        show_field_uuid: '',
        need_all: '0'
      },
      group: {
        // 分组
        field_uuid: '',
        show_field_uuid: '',
        name: ''
      },
      y: {
        // 指标
        field_uuid: '',
        show_field_uuid: '',
        method: 'count'
      }
    }
  ],
  attributes: { // 组件属性
    showTitle: true,
    title: '默认标题',
    textColor: '#000000',
    titleFontSize: 12,
    titleFontWeight: 400,
    titleLeft: 'auto',
    titleTop: 'auto',
    titleRight: 'auto',
    titleBottom: 'auto',
    showTooltip: true,
    showLegend: true,
    legendType: 'plain',
    legendTextColor: '#000000',
    legendIcon: 'circle',
    legendTop: 'auto',
    legendBottom: 'auto',
    legendLeft: 'auto',
    legendRight: 'auto',
    legendOrient: 'horizontal',
    left: '10%',
    right: '10%',
    top: 60,
    bottom: 60,
  },
  specialAttributes: {}, // 特殊配置
  sourceType: 'dynamic'
}
// 柱状图
export const barConfig = {
  ...defaultConfig,
  // 可用配置项(仅包含部分，后续如遇到特殊可持续追加)
  attributes: {
    ...defaultConfig.attributes,
    axisFontSize: 12,
    axisTextColor: '#000000',
    xAxisText: '',
    xAxisTextFontSize: 12,
    xAxisTextColor: '#000000',
    yAxisText: '',
    yAxisTextFontSize: 12,
    yAxisTextColor: '#000000',
    top: 10,
    bottom: 3,
    left: 3,
    right: 3,
    colorRange: ['#00c6b1', '#dac000', '#004cff'],
    lineColorRange: ['#00c6b1', '#dac000', '#004cff'],
    barWidth: 20,
    modal: false,
    showLine: false,
    showBarLabel: true,
    barChart: false,
    distinguishColor: false,
    XColorRange: ['#00c6b1', '#dac000', '#004cff'],
    useGradient: false, //使用渐变，
    gradientRange: [
      ['#00c6b1', '#D71919'],
      ['#dac000', '#D71919'],
      ['#004cff', '#D71919']
    ], // 渐变颜色组
    usePolar: false, // 是否使用极坐标
    showDataZoom: false,
    dataZoomBottom: 'auto',
    dataZoomStart: 0,
    dataZoomEnd: 100,
    minInterval: ''
  }
}

// 折线图
export const lineConfig = {
  ...defaultConfig,
  // 可用配置项(仅包含部分，后续如遇到特殊可持续追加)
  attributes: {
    ...defaultConfig.attributes,
    axisFontSize: 12,
    axisTextColor: '#000000',
    xAxisText: '',
    xAxisTextFontSize: 12,
    xAxisTextColor: '#000000',
    yAxisText: '',
    yAxisTextFontSize: 12,
    yAxisTextColor: '#000000',
    top: 10,
    bottom: 3,
    left: 3,
    right: 3,
    smooth: false,
    colorRange: ['#00c6b1', '#dac000', '#004cff'],
    showDataZoom: false,
    dataZoomBottom: 'auto',
    dataZoomStart: 0,
    dataZoomEnd: 100,
    useArea: false, // 使用面积
    userStack: false, // 使用堆叠
    useGradient: false, //使用渐变，
    gradientRange: [
      ['#00c6b1', '#D71919'],
      ['#dac000', '#D71919'],
      ['#004cff', '#D71919']
    ],
    minInterval: ''
  }
}

// 折柱混合
export const barLineConfig = {
  ...defaultConfig,
  // 可用配置项(仅包含部分，后续如遇到特殊可持续追加)
  attributes: {
    ...defaultConfig.attributes,
    axisFontSize: 12,
    axisTextColor: '#000000',
    xAxisText: '',
    xAxisTextFontSize: 12,
    xAxisTextColor: '#000000',
    yAxisText: '',
    yAxisTextFontSize: 12,
    yAxisTextColor: '#000000',
    top: 10,
    bottom: 3,
    left: 3,
    right: 3,
    colorRange: ['#00c6b1', '#dac000', '#004cff'],
    lineColorRange: ['#00c6b1', '#dac000', '#004cff'],
    barWidth: 20,
    modal: false,
    showBarLabel: true,
    barChart: false,
    XColorRange: ['#00c6b1', '#dac000', '#004cff'],
    showDataZoom: false,
    dataZoomBottom: 'auto',
    dataZoomStart: 0,
    dataZoomEnd: 100,
    differentY: false,
    yUnitLeft: '',
    yUnitRight: '',
    useGradient: false, //使用渐变，
    gradientRange: [
      ['#00c6b1', '#D71919'],
      ['#dac000', '#D71919'],
      ['#004cff', '#D71919']
    ], // 渐变颜色组
    useArea: false, // 使用面积
    userStack: false, // 使用堆叠
    minInterval: ''
  }
}
// 饼图
export const pieConfig = {
  ...defaultConfig,
  attributes: {
    ...defaultConfig.attributes,
    showLabel: true,
    labelColor: '#000000',
    labelSize: 12,
    colorRange: ['#00c6b1', '#dac000', '#004cff'],
    changeToRing: false,
    startRadius: 0,
    endRadius: 0,
    isRose: false,
    pieTop: '50%',
    pieLeft: '50%',
    borderWidth:0,
    borderRadius:0, // 间距圆角
    borderColor:'#FFF' // 间距颜色
  }
}

// 环图
export const ringConfig = {
  ...defaultConfig,
  attributes: {
    ...defaultConfig.attributes,
    showLabel: true,
    labelColor: '#000000',
    labelSize: 12,
    colorRange: ["#B0EFF7", "#51E3F7", "#23D2EA", "#0DB7CE", "#00889A", "#005F6B"],
    lessColor: ['rgba(0,0,0,0.45)'], // 底色
    changeToRing: false,
    isRose: false,
    centerPercent: true,
    clockwise: false, // 逆时针
    withBorder: false, // 边框
    barWidth: 50, // 宽度
    centerFontColor: '#2EBF76',
    centerFontSize: 24,
    centerFontTop: 42,
    descType: 'percent' // 描述类型
  }
}

// 翻牌器
export const flopConfig = {
  name: '', // 名称
  type: '', // 图表类型
  component: '', // 组件
  id: '',
  configs: [
    {
      key: '',
      label: '',
      view_uuid: '',
      field_uuid: '',
      aggregate: 4,
      isMoney: false,
      unit: ''
    }
  ],
  attributes: {
    width: '100%',
    theme: 'normal',
    showTitle: true,
    title: '默认标题',
    titleSize: 14,
    titleLineHeight: 30,
    titleColor: '#000000',
    titleAlign: 'center',
    showLabel: true,
    labelSize: 14,
    labelLineHeight: 30,
    labelColor: '#000000',
    labelAlign: 'center',
    valueSize: 14,
    valueColor: '#000000',
    valueAlign: 'Center',
    valueLineHeight: 30,
    unitSize: 12,
    unitColor: '#000000',
    unitLineHeight: 30,
    moneyType: '￥'
  }
}

// 漏斗图
export const funnelConfig = {
  ...defaultConfig,
  attributes: {
    ...defaultConfig.attributes,
    colorRange: ['#00c6b1', '#dac000', '#004cff']
  }
}

// 雷达图
export const radarConfig = {
  ...defaultConfig,
  attributes: {
    ...defaultConfig.attributes,
    colorRange: ['#00c6b1', '#dac000', '#004cff'],
    indicator: []
  }
}

// 横向进度图
export const completionRatioConfig = {
  type: '', // 图表类型
  component: '', // 组件
  id: '',
  attributes: {
    barHeight: 40,
    barWidth: '100%',
    barMarginTop: 30,
    showTitle: true,
    titleFontSize: 16,
    titleColor: '#fff',
    showCompleteCount: true,
    completeFontSize: 16,
    completeColor: '#fff',
    showPercent: true,
    percentFontSize: 16,
    percentColor: '#fff',
    completeBg: '#409EFF',
    background: '#fff',
    transparentBg: false,
    isRadius: true,
    showBorder: true,
    borderColor: '#fff',
    useGradient: false, //使用渐变，
    gradientRange: [
      ['#00c6b1', '#D71919'],
      ['#dac000', '#D71919'],
      ['#004cff', '#D71919']
    ],
  },
  sourceType: 'dynamic'
}

// 仪表盘

export const pointerConfig = {
  // ...defaultConfig,
  type: '', // 图表类型
  component: '', // 组件
  id: '',
  attributes: {
    positionX: '50%',
    positionY: '60%',
    // radius: '75%',
    backgroundColor: '#fff',
    // clockwise: true,
    // splitNumber: 10,
    // axisShow: true,
    // roundCap: false,
    lineStyleColor: '#E6EBF8',
    lineStyleWidth: 35,
    // progress: true,
    progressWidth: 30,
    // split: true,
    // splitLength: 10,
    // splitDistance: -45,
    // splitColor: '#63677A',
    // splitWidth: 3,
    // splitType: 'solid',
    mainColor: '#FFAB91',
    lessColor: '#FD7347',
    detailUnit: '',
    detailColor: 'auto',
    // detailShadow: true,
    // detailShadowColor: 'transparent',
    detailShadowBlur: 100,
    detailWidth: 200,
    detailHeight: 210,
    detailFontSize: 60,
    detailLineHeight: 60,
    borderRadius: 105,
    borderWidth: 1,
    borderColor: '#ffffff',
  },
  sourceType: 'dynamic'
};

export const configObject = {
  pie: pieConfig,
  line: lineConfig,
  bar: barConfig,
  barLine: barLineConfig,
  flop: flopConfig,
  funnel: funnelConfig,
  radar: radarConfig,
  ring: ringConfig,
  completionRatio: completionRatioConfig,
  pointer: pointerConfig
}