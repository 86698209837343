<template>
  <div class="etl-select-container">
    <header class="etl-select-header">
      <div class="search">
        <el-input v-model="searchVal" placeholder="搜索..." @keydown.enter="doSearch" @clear="handleClear"  class="search-input" clearable></el-input>
        <el-button type="primary" :loading="loading" size="mini" class="search-btn" @click="doSearch">
          <i class="iconfont iconsousuo1" />
        </el-button>
      </div>
      <p class="current-selected">
        当前选择： 
        <span>{{ activeItem.name || '-' }}</span>
      </p>
    </header>
    <div v-if="Array.isArray(options) && options.length" class="etl-select-warp" v-loading="loading">
      <div v-for="item in options" :class="{ active: activeItem.path === item.path }" class="etl-selet-item" :key="item.path" @click="doItemClick(item)">
        <span class="item-name">{{ item.name }}</span>
        <i v-if="activeItem.path === item.path" class="iconfont iconjijia_fuxuan2" style="color: #409eff" />
      </div>
    </div>
    <div v-else class="etl-select-warp">
      <el-empty description="暂无内容"></el-empty>
    </div>
    <el-pagination
      small
      @current-change="handlePageChange"
      :current-page.sync="page"
      :page-size="size"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
import { etl } from '@/saas-apis/bulletinBoard.js'
import { Empty  } from 'element-ui'
export default {
  components: {
    'el-empty': Empty
  },
  data() {
    return {
      options: [],
      activeItem: {},
      total: 0,
      page: 1,
      size: 30,
      searchVal: '',
      loading: false
    }
  },
  methods: {
    handlePageChange(v) {
      this.page = v
      this.getOptions()
    },
    handleClear() {
      this.page = 1
      this.getOptions()
    },
    doSearch() {
      this.page = 1
      this.getOptions()
    },
    getOptions() {
      const REQDATA = {
        size: this.size,
        page: this.page,
      }
      if(this.searchVal) {
        REQDATA.name = this.searchVal
      }
      this.loading = true
      etl.get(REQDATA).then(res => {
        if(res.data.code === 200) {
          this.options = res.data.data.data
          this.total = res.data.data.total
        }
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        this.loading = false
      })
    },
    doItemClick(item) {
      this.$emit('change', item)
      this.activeItem = item
    }
  },
  created() {
    this.getOptions()
  }
}
</script>
<style lang="less" scoped>
  .etl-select-container{
    width: 100%;
    .etl-select-header{
      padding: 5px;
      box-sizing: border-box;
      .search{
        width: 100%;
        display: flex;
        .seacrh-input{
          flex-grow: 1;
          width: 10px;
        }
        .search-btn{
          margin-left: 10px;
        }
      }
      .current-selected{
        margin-top: 10px;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .etl-select-warp{
      max-height: 300px;
      overflow: auto;
      margin-bottom: 10px;
      .etl-selet-item{
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        &:hover{
          background: #409eff22;
          color: #409eff;
        }
        .item-name{
          flex-grow: 1;
          width: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .active{
        background: #409eff22;
        color: #409eff;
      }
    }
  }
</style>
