<template>
  <div class="chart-container" v-loading="loading">
    <div class="chart-warp">
      <Chart v-if="!loading" :data="data" ref="chartRef" :firstData="firstData"/>
    </div>
    <div class="chart-config">
      <header class="chart-config-header">
        <div class="item" 
          v-for="(item, index) in typeOptions"
          :key="index"
          :class="{ active: data.etlOptions.type === item.type && data.etlOptions.yAxis === item.yAxis }"
          @click="doTypeSelected(item)"
        >
          <svg>
            <use :xlink:href="svgDict[item.type]" />
          </svg>
          <p>{{ dict[item.type] }}</p>
        </div>
      </header>
      <div class="chart-config-main">
        <ChartOptions :data.sync="data.chartData" @sure="handleSure" />
      </div>
      <footer class="chart-config-footer">
        <el-button type="primary" @click="handleSubmit">确认</el-button>
      </footer>
    </div>
  </div>
</template>
<script>
import Chart from './Chart'
import { etl } from '@/saas-apis/bulletinBoard.js'
import { barConfig, lineConfig, pieConfig} from '@/chart-components/config.js'
import { deepCopy } from '@/utils/tools'
import chartMixedConfig from './chartMixedConfig'
import ChartOptions from './ChartOptions'
const configDict = {
  bar: Object.assign({}, barConfig.attributes, chartMixedConfig),
  line: Object.assign({}, lineConfig.attributes, chartMixedConfig),
  pie: Object.assign({}, pieConfig.attributes, chartMixedConfig)
}
export default {
  components: {
    Chart,
    ChartOptions
  },
  props: {
    data: {
      type: Object,
      require: true,
      default() {
        return {}
      },
    }
  },
  data() {
    return {
      // 默认配置
      config: {},
      loading: false,
      firstData: {},
      typeOptions: [],
      dict: {
        bar: '柱状图',
        line: '折线图',
        pie: '饼状图',
        countup: '统计'
      },
      svgDict: {
        bar: '#iconshuzhu',
        line: '#iconzhe',
        pie: '#iconbing',
        countup: '#iconshuzi'
      }
    }
  },
  methods: {
    // 获取charts的数据
    getCharts() {
      if(this.data.etlConfig && this.data.etlConfig.path) {
        this.loading = true
        etl.getCharts(this.data.etlConfig.path).then(res => {
          if(res.data.code === 200) {
            if(!this.data.etlOptions) {
              if(res.data.data.list.length) {
                this.$set(this.data, 'etlOptions', res.data.data.list[0])
              } else {
                this.$message.info('当前选择数据无效！')
                return
              }
              this.firstData = res.data.data.firstData
              const attributes = deepCopy(configDict[this.data.etlOptions.type])
              this.$set(this.data, 'chartData', {
                type: this.data.etlOptions.type,
                attributes
              })
              this.config = this.data.chartData
            }
            this.typeOptions = res.data.data.list
          }
        }).finally(() => {
          this.loading = false
        }) 
      }
    },
    // 类型改变事件
    doTypeSelected(item) {
      this.data.etlOptions = item
      const attributes = deepCopy(configDict[this.data.etlOptions.type])
      this.$set(this.data, 'chartData', {
        type: this.data.etlOptions.type,
        attributes
      })
      this.config = this.data.chartData
    },
    // 提交
    handleSubmit() {
      this.$emit('submit')
    },
    // 确认 触发刷新
    handleSure() {
      if(this.$refs.chartRef) {
        this.$refs.chartRef.refresh()
      }
    }
  },
  created() {
    this.getCharts()
  }
}
</script>
<style lang="less" scoped>
  .chart-container{
    width: 100%;
    height: 60vh;
    display: flex;
    .chart-warp{
      flex-grow: 1;
      height: 100%;
      background: #f9f9f9;
    }
    .chart-config{
      border-left: 1px solid #f1f1f1;
      flex-shrink: 0;
      width: 300px;
      height: 100%;
      padding: 5px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      .chart-config-header{
        flex-shrink: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 5px;
        row-gap: 5px;
        .item{
          border-radius: 5px;
          text-align: center;
          padding: 5px;
          box-sizing: border-box;
          svg{
            width: 30px;
            height: 30px;
          }
          &:hover{
            background: #409eff33;
            color: #409eff;
          }
        }
        .active{
          background: #409eff33;
          color: #409eff;
        }
      }
      .chart-config-main{
        flex-grow: 1;
        height: 10px;
        overflow: auto;
      }
      .chart-config-footer{
        height: 40px;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        justify-content: flex-end;
        padding: 0 10px;
        box-sizing: border-box;
      }
    }
  }
</style>
